import { Controller } from "stimulus";

export default class extends Controller {
  connect() {}

  disconnect() {
    console.log("disconect corporate");
  }

  save(e) {}

  afterSave() {
    const companyId = this.data.get("company-id");

    toastr.success("Saved!");
    $("#modal").modal("hide");
    location.reload();
  }
}
