import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { validateEmail, validatePhone } from "../helpers/utils.js";

export default class extends Controller {
  static targets = [
    "step",
    "content",
    "managerName",
    "managerEmail",
    "managerMobileNumber",
    "name",
    "nameMeaning",
    "name2",
    "nameMeaning2",
    "name3",
    "nameMeaning3",
    "localAddress1",
    "localAddress2",
    "postCode",
    "city",
    "state",
    "country",
    "phoneNumber",
    "email",
    "natureBusiness",
    "msicCode",
    "paidUpShareCapital",
    "sourceFund",
    "expectedLocation",
    "nokName",
    "nokPassportNumber",
    "nokDateOfBirth",
    "nokNationality",
    "nokRace",
    "nokAddress",
    "nokRelationship",
    "nokCorrespondenceAddress",
    "nokEmail",
    "nokMobileNumber",
    "nokHomeNumber",
  ];

  showCurrentStep(stepIndex) {
    this.stepTargets.forEach((element, index) => {
      if (index == stepIndex) {
        element.setAttribute("data-wizard-state", "current");
      } else {
        element.removeAttribute("data-wizard-state");
      }
    });

    this.contentTargets.forEach((element, index) => {
      if (index == stepIndex) {
        element.setAttribute("data-wizard-state", "current");
      } else {
        element.removeAttribute("data-wizard-state");
      }
    });
  }

  changeStep(e) {
    const companyId = this.data.get("id");
    console.log(this.data.get("id"))
    let id = e.currentTarget.getAttribute("data-form-id");
    let currentStep = e.currentTarget.getAttribute("data-current-step");
    let url = `/admin/new_company_forms/${id}/update_step?current_step=step${currentStep}`;
    Turbo.visit(url);
  }

  changeState() {
    const el = document.getElementById("kt_wizard_v3");
    const count =
      this.companyShareholderController.getCountOfIndividualShareholder();
    if (this.index == 0) {
      el.setAttribute("data-wizard-state", "first");
    } else if (this.index == 4) {
      el.setAttribute("data-wizard-state", "last");
    } else {
      el.setAttribute("data-wizard-state", "between");
    }
  }

  next() {
    if (this.index == 0) {
      this.handleSaveStep1();
    } else if (this.index == 1) {
      this.handleSaveStep2();
    } else if (this.index == 2) {
      if (this.companyShareholderController.getSumOfShareholding() != 100) {
        toastr.warning("Sum of shareholder percetage must be equal to 100%");
        return;
      }

      if (
        this.companyShareholderController.getCountOfIndividualShareholder() != 1
      ) {
        this.index += 2;
      } else {
        this.index++;
      }
    } else if (this.index == 3) {
      this.handleSaveNextOfKin();
    } else {
      this.index++;
    }
  }

  previous() {
    if (this.index == 1) {
      this.handleSaveStep2("previous");
    } else if (this.index == 3) {
      this.handleSaveNextOfKin("previous");
    } else {
      this.index--;
    }
  }

  handleSaveStep1(action = "next") {
    const isEmailValid = validateEmail(this.managerEmailTarget.value);
    if (!isEmailValid) {
      toastr.warning("Invalid Email Address");
      return;
    }

    const isPhoneValid = validatePhone(this.managerMobileNumberTarget.value);
    if (!isPhoneValid) {
      toastr.warning("Invalid Phone Number");
      return;
    }

    this.save(!this.id ? "POST" : "PUT", this.buildStep1Params(), action);
  }

  handleSaveStep2(action = "next") {
    const isEmailValid = validateEmail(this.emailTarget.value);
    if (!isEmailValid) {
      toastr.warning("Invalid Email Address");
      return;
    }

    const isPhoneValid = validatePhone(this.phoneNumberTarget.value);
    if (!isPhoneValid) {
      toastr.warning("Invalid Phone Number");
      return;
    }

    this.save("PUT", this.buildStep2Params(), action);
  }

  buildStep1Params() {
    return {
      manager_email: this.managerEmailTarget.value,
      manager_name: this.managerNameTarget.value,
      manager_mobile_number: this.managerMobileNumberTarget.value,
      first_proposed_name: this.nameTarget.value,
      second_proposed_name: this.nameMeaningTarget.value,
      third_proposed_name: this.name2Target.value,
      first_meaning_name: this.nameMeaning2Target.value,
      second_meaning_name: this.name3Target.value,
      third_meaning_name: this.nameMeaning3Target.value,
    };
  }

  buildStep2Params() {
    return {
      address: this.localAddress1Target.value,
      detail_address: this.localAddress2Target.value,
      postcode: this.postCodeTarget.value,
      city: this.cityTarget.value,
      state: this.stateTarget.value,
      phone_number: this.phoneNumberTarget.value,
      email: this.emailTarget.value,
      business_nature: this.natureBusinessTarget.value,
      msic_id: this.msicCodeTarget.value,
      paid_up_share_capital: this.paidUpShareCapitalTarget.value,
      fund_origin: this.sourceFundTarget.value,
      expected_location: this.expectedLocationTarget.value,
    };
  }

  buildNextOfKinParams() {
    return {
      name: this.nokNameTarget.value,
      id_card_number: this.nokPassportNumberTarget.value,
      date_of_birth: this.nokDateOfBirthTarget.value,
      race: this.nokRaceTarget.value,
      nationality: this.nokNationalityTarget.value,
      address: this.nokAddressTarget.value,
      correspondence_address: this.nokCorrespondenceAddressTarget.value,
      relationshio: this.nokRelationship,
      email: this.nokEmailTarget.value,
      mobile_number: this.nokMobileNumberTarget.value,
      home_number: this.nokHomeNumberTarget.value,
    };
  }

  handleSaveNextOfKin(action = "next") {
    const isEmailValid = validateEmail(this.nokEmailTarget.value);
    if (!isEmailValid) {
      toastr.warning("Invalid Email Address");
      return;
    }

    const self = this;
    const params = this.buildNextOfKinParams();
    Rails.ajax({
      type: "POST",
      url: `/companies/${self.id}/next_of_kins`,
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        options.data = JSON.stringify(params);
        return true;
      },
      success: function (res) {
        toastr.success("Saved!");
        // action == "next" ? self.index++ : self.index--
        if (action == "next") {
          self.index++;
        } else if (action == "previous") {
          self.index--;
        }
        // else if(action == "submit"){
        //   location.href='/';
        // }
      },
      error: function (e) {
        toastr.warning(e.errors.message);
      },
    });
  }

  save(type, params, action = "next") {
    const self = this;
    let url = "/new_company_forms";
    if (type == "PUT") url = url + "/" + self.id;

    Rails.ajax({
      type: type,
      url: url,
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        options.data = JSON.stringify(params);
        return true;
      },
      success: function (res) {
        toastr.success("Saved!");
        self.id = res.data.id;
        action == "next" ? self.index++ : self.index--;
      },
      error: function (e) {
        toastr.warning(e.errors.message);
      },
    });
  }

  submit() {
    const self = this;
    let url = "/companies";
    url = url + "/" + self.id + "/complete";

    Rails.ajax({
      type: "POST",
      url: url,
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
        options.data = JSON.stringify({});
        return true;
      },
      success: function (res) {
        toastr.success("Register company completed!");
        location.href = "/";
      },
      error: function (e) {
        toastr.warning(e.errors.message);
      },
    });
  }

  buildSummaryData() {
    // Main Contact Person's Detail
    document.getElementById("summary-full-name").innerText =
      this.managerNameTarget.value;
    document.getElementById("summary-contact-number").innerText =
      this.managerMobileNumberTarget.value;
    document.getElementById("summary-email").innerText =
      this.managerEmailTarget.value;

    // COMPANY ENTITY NAMES AND ITS MEANING
    document.getElementById("summary-name1").innerText = this.nameTarget.value;
    document.getElementById("summary-meaning1").innerText =
      this.nameMeaningTarget.value;
    document.getElementById("summary-name2").innerText = this.name2Target.value;
    document.getElementById("summary-meaning2").innerText =
      this.nameMeaning2Target.value;
    document.getElementById("summary-name3").innerText = this.name3Target.value;
    document.getElementById("summary-meaning3").innerText =
      this.nameMeaning3Target.value;

    // Particulars of Company
    document.getElementById("summary-address1").innerText =
      this.localAddress1Target.value;
    document.getElementById("summary-address2").innerText =
      this.localAddress2Target.value;
    document.getElementById("summary-city").innerText = this.cityTarget.value;
    document.getElementById("summary-state").innerText = this.stateTarget.value;
    document.getElementById("summary-post-code").innerText =
      this.postCodeTarget.value;
    document.getElementById("summary-company-number").innerText =
      this.phoneNumberTarget.value;
    document.getElementById("summary-company-email").innerText =
      this.emailTarget.value;
    document.getElementById("summary-business-nature").innerText =
      this.natureBusinessTarget.value;
    document.getElementById("summary-msic-code").innerText =
      this.msicCodeTarget.value;
    document.getElementById("summary-expected-location").innerText =
      this.expectedLocationTarget.value;
    document.getElementById("summary-share-capital").innerText =
      this.paidUpShareCapitalTarget.value;
    document.getElementById("summary-source-fund").innerText =
      this.sourceFundTarget.value;
  }

  get index() {
    return parseInt(this.data.get("index"));
  }

  set index(value) {
    this.data.set("index", value);
    this.showCurrentStep(value);
    this.changeState();
    if (value == 4) {
      // Review and Submit
      this.buildSummaryData();
    }
  }

  get id() {
    return this.data.get("id") == "" ? null : parseInt(this.data.get("id"));
  }

  set id(value) {
    this.data.set("id", value);
  }

  get companyShareholderController() {
    const element = document.getElementById("company-shareholder");
    return this.application.getControllerForElementAndIdentifier(
      element,
      "company-shareholder"
    );
  }
}
