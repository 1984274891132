import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    this.loadList()
  }

  loadList(){
    Rails.ajax({
      type: 'GET',
      url: '/user_companies',
      dataType: 'script',
    });
  }

  reloadList(){
    Rails.ajax({
      type: 'GET',
      url: '/user_companies',
      dataType: 'script',
    });
  }

}
