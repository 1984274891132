import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "shareholdingPercentage" ]

  connect() {
    this.loadList();
  }

  loadList(){
    const company_id = this.companyController.id
    const self = this;
    if(company_id){
      Rails.ajax({
        type: 'GET',
        url: `/companies/${company_id}/shareholders`,
        dataType: 'script',
        success: function () { self.getSumOfShareholding() },
      });
    }
  }

  getSumOfShareholding() {
    const sum = this.shareholdingPercentageTargets.reduce((prevValue, element) => {
      return prevValue += parseInt(element.textContent)
    }, 0)
    return sum;
  }

  edit(event){
    const type = event.currentTarget.getAttribute('type');
    const id = parseInt(event.currentTarget.id);
    if(type == "individual"){
      this.companyShareholderController.showModalIndividual(null, id);
    }else{
      this.companyShareholderController.showModalCorporate(null, id);
    }
  }

  delete(event){
    const id = parseInt(event.currentTarget.id);
    const company_id = this.companyController.id
    const self = this;
    Rails.ajax({
      type: "DELETE",
      url: `/companies/${company_id}/shareholders/${id}`,
      success: function (res) {
        self.loadList();
      }
    });
  }

  get companyShareholderController() {
    const element = document.getElementById("company-shareholder")
    return this.application.getControllerForElementAndIdentifier(element, "company-shareholder")
  }

  get companyController() {
    const element = document.getElementById("company")
    return this.application.getControllerForElementAndIdentifier(element, "company")
  }
}
