import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = [
    'searchText', 
    'table',
    'status',
    'supplier',
    'workOrderId',
    'documentDate',
    'reference',
    // 'amountSign',
    // 'amountValue'
  ]

  connect() {
    this.mainModal = document.getElementById('exampleModalLong')
    this.modal = new Modal(this.mainModal)
  }

  setTable(e){
    this.table = e.detail
    this.modal.show()
  }

  filter(){
    this.table.columns(0).search(this.workOrderIdTarget.value).draw();
    this.table.columns(1).search(this.documentDateTarget.value).draw();
    this.table.columns(2).search(this.supplierTarget.value).draw();
    // if(this.amountSignTarget && this.amountValueTarget && this.amountSignTarget.innerHTML && this.amountValueTarget.value){
    //   let amount = parseFloat(this.amountValueTarget.value)
    //   let amountSign = this.amountSignTarget.innerHTML
    //   console.log(amount, amountSign)
    //   $.fn.dataTable.ext.search.pop();
    //   $.fn.dataTable.ext.search.push(
    //     function(settings, data, dataIndex) {
    //       var dataAmount = parseFloat(data[5].replace('MYR ',''));
    //       console.log(amount,dataAmount)
    //       console.log(amount > dataAmount)
    //       if (dataAmount && amountSign == 'Equal' && amount === dataAmount) {
    //         return true;
    //       }
    //       if (dataAmount && amountSign == 'Less than' && dataAmount < amount ) {
    //         return true;
    //       }
    //       if (dataAmount && amountSign == 'More than' && dataAmount > amount) {
    //         return true;
    //       }
    //       return false;
    //     }
    //   )
    // }
    this.table.columns(4).search(this.referenceTarget.value).draw();
    this.table.columns(6).search(this.statusTarget.value).draw();    
    this.modal.hide()
  }

  resetFilter(){
    this.workOrderIdTarget.value = ''
    this.documentDateTarget.value = ''
    this.supplierTarget.value = ''
    this.referenceTarget.value = ''
    this.statusTarget.value = ''
    // this.amountValueTarget.value = ''
    $.fn.dataTable.ext.search.pop();
    this.table.columns().search('').draw()
    this.modal.hide()
  }
} 