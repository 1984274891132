import { Controller } from "stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  connect() {}

  showModalDirector() {
    Rails.ajax({
      type: "GET",
      url: `/directors/new?form=director_form`,
      dataType: "script",
      success: function () {
        $("#modal").modal();
        $("#modal")
          .find(".modal-body")
          .first()
          .after(
            '<input name="shareholder[shareholder_type]" value="individual" hidden>'
          );
      },
    });
  }

  get companyController() {
    const element = document.getElementById("company");
    return this.application.getControllerForElementAndIdentifier(
      element,
      "company"
    );
  }
}
