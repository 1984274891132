import Dropzone from "dropzone";
import { Controller } from "stimulus";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "../helpers/dropzone";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    // Dropzone.forElement(".dropzone").options.autoProcessQueue = false;
  }

  // Private
  hideFileInput() {
    this.inputTarget.style.position = "absolute"
    this.inputTarget.style.opacity = "0"
    this.inputTarget.style.bottom = "0"
    this.inputTarget.style.left = "40%"
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      const hiddenInput = this.inputTarget;

      const existingFiles = hiddenInput.files || [];
      const dt = new DataTransfer();

      for (let i = 0; i < existingFiles.length; i++) {
        dt.items.add(existingFiles[i]);
      }

      dt.items.add(file);

      hiddenInput.files = dt.files;
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);

      const hiddenInput = this.inputTarget;

      const existingFiles = hiddenInput.files || [];
      const dt = new DataTransfer();

      for (let i = 0; i < existingFiles.length; i++) {
        if (file !== existingFiles[i]) {
          dt.items.add(existingFiles[i]);
        }
      }

      hiddenInput.files = dt.files;
      console.log(hiddenInput.files);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: 'https://example.com/upload',
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    autoProcessQueue: true
  });
}
