import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
// import Turbo from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ["rows", "status"]

  updateText(){
    event.preventDefault()
    const newStatus = event.currentTarget.dataset.dropdownMenuStatus
    this.statusTarget.innerHTML = newStatus
  }

}
