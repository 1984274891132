import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  reload(){
    if(this.element.value === 'add_new_company'){
      Turbo.visit(`/companies/new`)
    }else{
      Turbo.visit(`/dashboards?switch_company=${this.element.value}`)
    }
  }

}
