import * as Turbo from "@hotwired/turbo";
export { Turbo };

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("@hotwired/turbo-rails");
require("datatables.net-bs4");

import $ from "jquery";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";

global.jQuery = $;
global.$ = $;
global.Turbo = Turbo;

//require("template/plugins/global/plugins.bundle.js")
//require("template/plugins/global/select2-4.1.0-rc.0/dist/select2.js");
//require("template/plugins/custom/prismjs/prismjs.bundle.js")
//require("template/js/scripts.bundle.js")
//require("template/plugins/custom/fullcalendar/fullcalendar.bundle.js");
//require("template/js/pages/crud/forms/widgets/select2.js")
///require("template/js/pages/crud/forms/validation/form-controls.js")

//require("template/plugins/custom/datatables/datatables.bundle.js")
//require("template/js/pages/crud/datatables/advanced/row-grouping.js")


import toastr from "toastr";
import "toastr/build/toastr.css";
window.toastr = toastr;
import select2 from "select2";
import "bootstrap";
import "bootstrap-datepicker";
import "controllers";

$.extend($.fn.dataTable.defaults, {
  responsive: true,
  pagingType: "full_numbers",
  autoWidth: false,
  stateSave: false,
  select: true,
  language: {
    // emptyTable: `<h3 class="text-center mt-5">No data</h3>`,
    loadingRecords:
      "<div class='sk-circle'> <div class='sk-circle1 sk-child'></div> <div class='sk-circle2 sk-child'></div> <div class='sk-circle3 sk-child'></div> <div class='sk-circle4 sk-child'></div> <div class='sk-circle5 sk-child'></div> <div class='sk-circle6 sk-child'></div> <div class='sk-circle7 sk-child'></div> <div class='sk-circle8 sk-child'></div> <div class='sk-circle9 sk-child'></div> <div class='sk-circle10 sk-child'></div> <div class='sk-circle11 sk-child'></div> <div class='sk-circle12 sk-child'></div> </div>",
  },
});

document.addEventListener("turbo:load", function () {
  $('[data-toggle="tooltip"]').tooltip();
  $(".select2").select2();
  $("#list-company").select2({
    minimumResultsForSearch: Infinity,
  });
  $(".datepicker").datepicker({
    format: "dd-mm-yyyy",
  });
  
});