import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
export default class extends Controller {
  static targets = [ "shareholdingPercentage", "shareholderType" ]

  connect(){
    this.loadList();
  }

  loadList(){
    const new_company_form_id = this.companyController.id
    const self = this;
    if(new_company_form_id){
      Rails.ajax({
        type: 'GET',
        url: `/new_company_forms/${new_company_form_id}/draft_shareholders`,
        dataType: 'script',
        success: function () {
          const count = self.getCountOfIndividualShareholder();
        },
      });
    }
  }

  getSumOfShareholding() {
    const sum = this.shareholdingPercentageTargets.reduce((prevValue, element) => {
      return prevValue += parseInt(element.textContent)
    }, 0)
    return sum;
  }

  getCountOfIndividualShareholder() {
    const count = this.shareholderTypeTargets.reduce((prevValue, element) => {
      return prevValue += (element.textContent == "individual" ? 1 : 0)
    }, 0)

    return count;
  }

  delete(event){
    const id = parseInt(event.currentTarget.id);
    const company_id = this.companyController.id
    const self = this;
    Rails.ajax({
      type: "DELETE",
      url: `/new_company_forms/${company_id}/draft_shareholders/${id}`,
      success: function (res) {
        self.loadList();
      }
    });
  }

  showModalIndividual(){
    const company_id = this.companyController.id
    Rails.ajax({
      type: 'GET',
      url: `/new_company_forms/${company_id}/draft_shareholders/new?form=individual_form`,
      dataType: 'script',
      success: function () {
        $('#modal').modal();
        $("#modal").find('.modal-body').first().after('<input name="draft_shareholder[shareholder_type]" value="individual" hidden>');
      },
    });
  }

  editModalIndividual(event){
    const id = parseInt(event.currentTarget.id);
    const company_id = this.companyController.id

    Rails.ajax({
      type: 'GET',
      url: `/new_company_forms/${company_id}/draft_shareholders/${id}/edit?form=individual_edit`,
      dataType: 'script',
      success: function () {
        $('#modal').modal();
        $("#modal").find('.modal-body').first().after('<input name="shareholder[shareholder_type]" value="individual" hidden>');
      },
    });
  }

  showModalCorporate(){
    const company_id = this.companyController.id

    Rails.ajax({
      type: 'GET',
      url: `/new_company_forms/${company_id}/draft_shareholders/new?form=corporate_form`,
      dataType: 'script',
      success: function () {
        $('#modal').modal();
        $("#modal").find('.modal-body').first().after('<input name="draft_shareholder[shareholder_type]" value="corporate" hidden>');
      },
    });
  }

  editModalCorporate(event){
    const id = parseInt(event.currentTarget.id);
    const company_id = this.companyController.id

    Rails.ajax({
      type: 'GET',
      url: `/new_company_forms/${company_id}/draft_shareholders/${id}/edit?form=corporate_edit`,
      dataType: 'script',
      success: function () {
        $('#modal').modal();
        $("#modal").find('.modal-body').first().after('<input name="shareholder[shareholder_type]" value="corporate" hidden>');
      },
    });
  }

  get companyController() {
    const element = document.getElementById("company")
    return this.application.getControllerForElementAndIdentifier(element, "company")
  }
}