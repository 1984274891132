import { Controller } from "stimulus";

export default class extends Controller {
  connect() {}

  disconnect() {
    console.log("disconect individual");
  }

  save(e) {}

  afterSave() {
    const companyId = this.data.get("company-id");
    console.log(companyId)
    toastr.success("Saved!");
    $("#modal").modal("hide");
    location.reload();
  }
}
