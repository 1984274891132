import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
// import Turbo from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ["rows", "status"]

  connect() {
    // this.loadList()
    // console.log('work order')
    // console.log(this.data.get('url'))
  }

  loadList(){
    Rails.ajax({
      type: 'GET',
      url: this.data.get('url'),
      dataType: 'script',
    });
  }

  reloadList(){
    Rails.ajax({
      type: 'GET',
      url: this.data.get('url'),
      dataType: 'script',
    });
  }

  show(){
    let url = event.currentTarget.dataset.workOrderShowUrl
    Turbo.visit(url)
  }

  updateStatus(){
    const newStatus = event.currentTarget.dataset.workOrderStatus
    this.statusTarget.innerHTML = newStatus
  }

}
