import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ['searchText', 'table','companyName','accSubcriptionStatus','cosecSubcriptionStatus',  'status', 'email', 'assignedManager', 'assignedStaff']

  connect() {
    this.mainModal = document.getElementById('exampleModalLabelCompany')
    this.modal = new Modal(this.mainModal)
  }

  setTable(e){
    this.table = e.detail
    this.modal.show()
  }

  filter(){
      this.table.columns(0).search(this.companyNameTarget.value).draw();
      if (this.accSubcriptionStatusTarget.value !== "") {
        this.table.columns(1).search('^' + this.accSubcriptionStatusTarget.value + '$', true, false).draw();
      }
      else{
        this.table.columns(1).search(this.accSubcriptionStatusTarget.value).draw();
      }
      if (this.cosecSubcriptionStatusTarget.value !== "") {
        this.table.columns(2).search('^' + this.cosecSubcriptionStatusTarget.value + '$', true, false).draw();
      }
      else{
        this.table.columns(2).search(this.cosecSubcriptionStatusTarget.value).draw();
      }


      this.table.columns(3).search(this.statusTarget.value).draw();
      this.table.columns(4).search(this.emailTarget.value).draw();
      this.table.columns(5).search(this.assignedManagerTarget.value).draw();
      this.table.columns(6).search(this.assignedStaffTarget.value).draw();
      this.modal.hide()
    }

  resetFilter(){
    this.companyNameTarget.value = ''
    this.accSubcriptionStatusTarget.value = ''
    this.cosecSubcriptionStatusTarget.value = ''
    this.statusTarget.value = ''
    this.emailTarget.value = ''
    this.assignedManagerTarget.value = ''
    this.assignedStaffTarget.value = ''
    this.filter()
  }
} 