import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "pickState",
    "pickCode",
    "deliveryType",
    "weight",
    "width",
    "height", 
    "length",
    "loadPickPointButton",
  ];
  loadPickPoint() {
    const pickState = this.pickStateTarget.value;
    const pickCode = this.pickCodeTarget.value;
    var deliveryType = this.deliveryTypeTarget.value;
    const weight = this.weightTarget.value;
    const width = this.widthTarget.value;
    const height = this.heightTarget.value;
    const length = this.lengthTarget.value;
    if(!this.deliveryTypeTarget.checked){
      deliveryType = "pickup"
    }
    console.log(this.loadPickPointButtonTarget);
    // this.loadPickPointButtonTarget.disabled = false;
    // this.loadPickPointButtonTarget.innerHTML = "Get pickup point";
    Rails.ajax({
      type: "GET",
      url: `/work_orders/easy_parcel_point?pick_state=${pickState}&pick_code=${pickCode}&delivery_type=${deliveryType}&
      weight=${weight}&length=${length}&width=${width}&height=${height}`,
      dataType: "script",
      success: (response) => (this.loadPickPointButtonTarget.hidden = true),
      error: (response) => (this.loadPickPointButtonTarget.disabled = false),
      // this.loadPickPointButtonTarget.hidden = true
      // complete: (response) => (this.loadPickPointButtonTarget.disabled = false),
      // error: (response) => (this.loadPickPointButtonTarget.disabled = false),
    });
  }
}
