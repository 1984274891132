import { Controller } from 'stimulus'; 
export default class extends Controller {
  connect() {
    console.log("hello from StimulusJS")
  }

  reset() {
    console.log('reset')
    this.element.reset()
  }

}