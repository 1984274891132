import { Controller } from 'stimulus'; 
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    console.log("hello from play ground company")
    this.loadList()
  }

  loadList(){
    Rails.ajax({
      type: 'GET',
      url: '/playground/companies', // <- PlaygroundCompanies#index.js.slim
      dataType: 'script', // remote -> true = AS JS
    });
  }

  reloadList(){
    Rails.ajax({
      type: 'GET',
      url: '/playground/companies', // <- PlaygroundCompanies#index.js.slim
      dataType: 'script', // remote -> true = AS JS
    });
  }

}


