import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){}

  disconnect(){
    console.log("disconect director");
  }

  save (e) {}

  afterSave(){
    toastr.success("Saved!");
    $('#modal').modal('hide');
    location.href='/new_company_forms/step4';
  }
}