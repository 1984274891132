import { Controller } from "stimulus"

// example initialize datatable //
// table data-controller='datatable' data-datatable-config="#{
// {
//   debug: true,
//   serverSide: true,
//   ajax: 'testing',
//   dom: 'lfriptrip',
//   columns: [
//     {title: 'Title', data: 'title', width: "30%" },
//     {title: 'Text', data: 'text', },
//   ],
// }.to_json }"

export default class extends Controller {
  static targets = ['searchText', 'table']

  isDataTable = () => this.element.className.includes('dataTable')

  connect() {
    this.initDatatable()
  }

  initDatatable(){
    if (this.isDataTable()) return false
    this.table = window
      .jQuery(this.tableTarget)
      .DataTable(this.getConfig())
  }

  // getter for config
  getConfig(){
    return this.data.get('config') ? JSON.parse(this.data.get('config')) : {}
  }

  toggleAdvanceFilter(){
    const event = new CustomEvent("inner-connected", {detail: this.table})
    document.dispatchEvent(event)
  }

}