import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ['searchText', 'table', 'workOrderId', 'documentDate', 'supplier', 'modal', 'createdBy', 'assignedTo', 'status', 'reference', 'companyName']

  connect() {
    this.mainModal = document.getElementById('exampleModalLong')
    this.modal = new Modal(this.mainModal)
  }

  setTable(e){
    this.table = e.detail
    this.modal.show()
  }

  filter(){
    this.table.columns(0).search(this.workOrderIdTarget.value).draw();
    this.table.columns(1).search(this.documentDateTarget.value).draw();
    this.table.columns(2).search(this.supplierTarget.value).draw();
    this.table.columns(4).search(this.referenceTarget.value).draw();
    this.table.columns(5).search(this.statusTarget.value).draw();
    this.table.columns(6).search(this.createdByTarget.value).draw();
    this.table.columns(7).search(this.assignedToTarget.value).draw();
    this.table.columns(8).search(this.companyNameTarget.value).draw();
    this.modal.hide()
  }

  resetFilter(){
    this.workOrderIdTarget.value = ''
    this.documentDateTarget.value = ''
    this.supplierTarget.value = ''
    this.referenceTarget.value = ''
    this.statusTarget.value = ''
    this.createdByTarget.value = ''
    this.assignedToTarget.value = ''
    this.companyNameTarget.value = ''
    this.filter()
  }
} 