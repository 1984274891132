import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    console.log('upload doc sidebar')
  }

  onPublishXero(){
    console.log('publishing xero')
  }

}
